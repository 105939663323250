<template>
  <div class="AdminStatisticsGeneralPosts">
    <div :class="`statistics-block __general ${$loading ? '__loading' : '__loaded'}`">
      <div class="ReportInfo">
        <LargeButton
          :icon="false"
          :label="$locale['total_posts']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalPosts)}</strong>`"
          @uclick="setQuery({ admin: 'statistics-fans', startDate, endDate, period: 'custom' })"
          prevent
        />
        <LargeButton
          :icon="false"
          :label="$locale['total_subscriptions']"
          :rightDesc="`<strong skeleton>${number_format(statistics.totalSubscriptions)}</strong>`"
          @uclick="setQuery({ admin: 'statistics-creators', startDate, endDate, period: 'custom' })"
          prevent
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["statistics"],
  computed: {
    startDate: function() {
      return this.$route.query.startDate || this.statistics.startDate;
    },
    endDate: function() {
      return this.$route.query.endDate || this.statistics.endDate;
    },
  },
};
</script>
